.checkbox {
    display: inline-flex;
    align-items: center;
    position: relative;
}
.checkbox-icon {
    height: 30px;
    width: 30px;
    text-align: center;
    border: 1px solid #cdcdcd;
    font-size: 20px;
    pointer-events: none;
}
.checkbox-icon svg {
    position: absolute;
    left: 6px;
    top: 5px;
    display: none;
    fill: #fff;
}
.checkbox-control {
    position: absolute;
    left: 1px;
    top: 1px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    opacity: 0;
}
.checkbox-control:hover + .checkbox-icon {
    background-color: #f3f3f3;
}
.checkbox-control:checked + .checkbox-icon {
    background-color: #d72000;
}
.checkbox-control:checked + .checkbox-icon svg {
    display: inline;
}
.checkbox.disabled .checkbox-icon {
    background-color: #ebebeb;
}
.checkbox.disabled .checkbox-control {
    pointer-events: none;
}
.checkbox-label {
    margin-left: 10px;
}
