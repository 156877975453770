@font-face {
    font-family: HelveticaNeueRegular;
    src: url('./fonts/HelveticaNeue.woff');
}
@font-face {
    font-family: InterUI;
    src: url('./fonts/Inter-UI-Regular.woff');
}

body {
    margin: 0;
    font-size: 20px;
}
